import commonStore, { CommonStore } from './commonStore';
import airportDataStore, { AirportDataStore } from './airportDataStore';
import accountInformationStore, { AccountInformationStore } from './accountInformationStore';
import serviceDataStore, { ServiceDataStore } from './serviceDataStore';
import flightDataStore, { FlightDataStore } from './flightDataStore';
import partnerStore, { PartnerStore } from '@/stores/partnerStore';
import hotelStore, { HotelStore } from '@/stores/hotelStore';
import selectedPartnerAndServiceStore, {
  SelectedPartnerAndServiceStore,
} from '@/stores/selectedPartnerAndServiceStore';
import destinationStore, { DestinationStore } from '@/stores/destinationStore';

export type RootStore = {
  commonStore: CommonStore;
  airportDataStore: AirportDataStore;
  accountInformationStore: AccountInformationStore;
  serviceDataStore: ServiceDataStore;
  flightDataStore: FlightDataStore;
  partnerStore: PartnerStore;
  selectedPartnerAndServiceStore: SelectedPartnerAndServiceStore;
  destinationStore: DestinationStore;
  hotelStore: HotelStore;
};

const rootStore: RootStore = {
  commonStore,
  airportDataStore,
  accountInformationStore,
  serviceDataStore,
  flightDataStore,
  partnerStore,
  selectedPartnerAndServiceStore,
  destinationStore,
  hotelStore,
};

export default rootStore;
