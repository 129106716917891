import React, { FC, lazy } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { getPath } from '@routes/router-paths';
import NotFoundPage from '@pages/NotFoundPage';
import LoginPage from '@pages/LoginPage';
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
  ParamsSerializerOptions,
} from 'axios';
import { message } from 'antd';
import qs from 'query-string';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { TQueryConfig } from '@/utils/httpClient/httpClient';

const history = createBrowserHistory();

const loadingQueue: number[] = [];
NProgress.configure({ showSpinner: false });

const stopLoadingSpinner = () => {
  if (!loadingQueue.length) {
    NProgress.done();
  }
};

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    NProgress.start();
    loadingQueue.push(1);
    return config;
  },
  (error: AxiosError) => {
    loadingQueue.pop();
    stopLoadingSpinner();
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    loadingQueue.pop();
    stopLoadingSpinner();
    return response;
  },
  (error: AxiosError) => {
    const { response, code, config } = error;
    const { manuallyHandlingErrorMsg } = config as AxiosError['config'] & TQueryConfig;
    if (response?.status === 401) {
      if (!['/MemAuth/Authenticate', '/MemAuth/Me'].includes(config?.url as string)) {
        localStorage.removeItem('accessToken');
        history.push({
          pathname: getPath('login'),
          state: {
            from: window.location.pathname,
          },
        });
      }
    }
    if (response?.status === 403) {
      history.push(getPath('portal'));
    }
    if (response?.status === 408 || code === 'ECONNABORTED') {
      !manuallyHandlingErrorMsg && message.error('Quá thời gian chờ, xin vui lòng thử lại!');
    }
    loadingQueue.pop();
    stopLoadingSpinner();
    !manuallyHandlingErrorMsg &&
      // @ts-ignore
      message.error(response?.data?.message ?? 'Có lỗi xảy ra, xin vui lòng thử lại!');
    return Promise.reject(manuallyHandlingErrorMsg ? response?.data : error);
  }
);

const paramsSerializerOptions: ParamsSerializerOptions = {
  serialize: params => qs.stringify(params),
};
axios.defaults.paramsSerializer = paramsSerializerOptions;

const MainRoutes = lazy(() => import('@routes/MainRoutes'));

const AppRoutes: FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={getPath('login')} component={LoginPage} />
        <MainRoutes />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
